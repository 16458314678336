// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-design-system-js": () => import("./../../../src/pages/portfolio/design-system.js" /* webpackChunkName: "component---src-pages-portfolio-design-system-js" */),
  "component---src-pages-portfolio-koopio-js": () => import("./../../../src/pages/portfolio/koopio.js" /* webpackChunkName: "component---src-pages-portfolio-koopio-js" */),
  "component---src-pages-portfolio-onelogin-js": () => import("./../../../src/pages/portfolio/onelogin.js" /* webpackChunkName: "component---src-pages-portfolio-onelogin-js" */),
  "component---src-pages-portfolio-oracle-ccs-js": () => import("./../../../src/pages/portfolio/oracle-ccs.js" /* webpackChunkName: "component---src-pages-portfolio-oracle-ccs-js" */),
  "component---src-pages-portfolio-oracle-cloud-js": () => import("./../../../src/pages/portfolio/oracle-cloud.js" /* webpackChunkName: "component---src-pages-portfolio-oracle-cloud-js" */),
  "component---src-pages-portfolio-syncables-js": () => import("./../../../src/pages/portfolio/syncables.js" /* webpackChunkName: "component---src-pages-portfolio-syncables-js" */)
}

